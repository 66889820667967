<template>
  <div :class="$vuetify.breakpoint.mdAndUp ? 'px-12 my-12' : 'px-2 my-4'">
    <v-snackbar class="pa-0" light timeout="-1" v-model="alertShow" width="fit-content" top centered>
      <v-alert
        v-model="alertShow"
        dismissible
        :icon="alertSuccess ? 'check_circle' : 'cancel'"
        class="mb-0 pa-1"
        :class="alertSuccess ? 'profile-alert-success' : 'profile-alert-failed'"
        light
        close-icon="close"
      >
        {{ alertMessage }}
      </v-alert>
    </v-snackbar>
    <!--    desktop-->
    <v-card elevation="0" class="justify-space-around referralLayout hidden-sm-and-down">
      <v-img src="/static/image/referral/referral_banner.png" :aspect-ratio="1820 / 420">
        <div class="fill-height">
          <v-row no-gutters align="end" justify="end" class="fill-height">
            <v-col cols="6">
              <label class="ml-3 white--text">{{ $t(`label.referralProgram`) }}</label>
              <p class="ml-3 white--text subtitle-2">
                {{ $t(`label.referralProgramText`) }}
              </p>
            </v-col>
            <v-col cols="12" class="text-right">
              <v-btn elevation="0" class="referral-tab" to="tier" active-class="primary" depressed @click="selectedTab = 'inbox'">{{ $t(`button.referralTier`) }}</v-btn>
              <v-btn elevation="0" class="referral-tab" to="summary" active-class="primary" depressed @click="selectedTab = 'announcement'">{{ $t(`button.summary`) }}</v-btn>
              <v-btn elevation="0" class="referral-tab" to="report" active-class="primary" depressed @click="selectedTab = 'inbox'">{{ $t(`button.referralReport`) }}</v-btn>
              <v-btn elevation="0" class="referral-tab" to="history" active-class="primary" depressed @click="selectedTab = 'announcement'">{{ $t(`button.redeemHistory`) }}</v-btn>
            </v-col>
          </v-row>
        </div>
      </v-img>
      <v-card-text v-if="isLoggedIn || currentRouteName == 'tier'" class="referralContainer">
        <router-view></router-view>
      </v-card-text>
      <v-card-text v-else class="referralContainer">
        <v-row v-if="!isLoggedIn">
          <v-col cols="12" class="text-center">
            <label color="title_color2">{{ $t(`message.referralNoLogin`) }}</label>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-btn height="auto" @click="openLoginDialog" color="title_color" class="mr-6 primary-button embedded-login-button pa-2 font-weight-bold yellow--text subtitle-1">
              {{ $t(`button.login`) }}
            </v-btn>
            <app-button
              :customClass="'buttonPrimary subtitle-1 full-width font-weight-bold pa-2 theme-button embedded-register-button'"
              :action="openRegisterDialog"
              :title="$t(`label.signUp`)"
            ></app-button>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card elevation="0" class="justify-space-around referralLayout hidden-md-and-up">
      <v-img src="/static/image/referral/referral_banner.png" :aspect-ratio="1820 / 420">
        <!-- <div class="fill-height"> -->
        <v-row class="" no-gutters justify="end">
          <v-col cols="12">
            <v-expansion-panels accordion flat class="mobile-referral-expansion">
              <v-expansion-panel>
                <v-expansion-panel-header class="white--text">
                  <label class="white--text">{{ $t(`label.referralProgram`) }}</label>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="white--text subtitle-2 elevation-0">
                  {{ $t(`label.referralProgramText`) }}
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
          <!--          <v-col cols="12" class="text-end d-flex justify-center align-end">-->
          <!--            <v-btn elevation="0" class="referral-tab-mobile" to="tier" active-class="mobile-nav-active" depressed>-->
          <!--              <referral-tier class="mobile-icon"></referral-tier>-->
          <!--              <span class="ml-1" v-if="$route.name == 'tier'">{{ $t(`button.referralTier`) }}</span>-->
          <!--            </v-btn>-->
          <!--            <v-btn elevation="0" class="referral-tab-mobile" to="summary" active-class="mobile-nav-active" depressed>-->
          <!--              <referral-summary class="mobile-icon"></referral-summary>-->
          <!--              <span class="ml-1" v-if="$route.name == 'summary'">{{ $t(`button.summary`) }}</span>-->
          <!--            </v-btn>-->
          <!--            <v-btn elevation="0" class="referral-tab-mobile" to="report" active-class="mobile-nav-active" depressed>-->
          <!--              <referral-report class="mobile-icon"></referral-report>-->
          <!--              <span class="ml-1" v-if="$route.name == 'report'">{{ $t(`button.referralReport`) }}</span>-->
          <!--            </v-btn>-->
          <!--            <v-btn elevation="0" class="referral-tab-mobile" to="history" active-class="mobile-nav-active" depressed>-->
          <!--              <referral-history class="mobile-icon"></referral-history>-->
          <!--              <span class="ml-1" v-if="$route.name == 'history'">{{ $t(`button.redeemHistory`) }}</span>-->
          <!--            </v-btn>-->
          <!--          </v-col>-->
        </v-row>

        <!-- </div> -->
      </v-img>
      <v-row class="mobile-header referral no-gutters">
        <v-col cols="6" class="text-center mobile-header-item" :class="$route.name == routeName.REFERRAL_TIER ? 'selected' : ''" @click="naviToTier">
          <router-link :to="routeName.REFERRAL_TIER" style="text-decoration: unset !important">
            <span>{{ $t(`mobileMenuTitle.myReferral`) }}</span>
          </router-link>
        </v-col>
        <v-col cols="6" class="text-center mobile-header-item" :class="$route.name == routeName.REFERRAL_SUMMARY ? 'selected' : ''" @click="naviToSummary">
          <router-link :to="routeName.REFERRAL_SUMMARY" style="text-decoration: unset !important">
            <span>{{ $t(`mobileMenuTitle.myReferralCom`) }}</span>
          </router-link>
        </v-col>
      </v-row>
      <v-card-text v-if="isLoggedIn || currentRouteName == 'tier'" class="referralContainer">
        <router-view></router-view>
      </v-card-text>
      <v-card-text v-else class="referralContainer">
        <!--        desktop before login view-->
        <v-row v-if="!isLoggedIn" class="hidden-sm-and-down">
          <v-col cols="12" class="text-center">
            <label color="title_color2">{{ $t(`message.referralNoLogin`) }}</label>
          </v-col>
          <v-col cols="12" class="text-center hidden-sm-and-down">
            <v-btn height="auto" @click="openLoginDialog" color="title_color" class="mr-6 primary-button embedded-login-button pa-2 font-weight-bold yellow--text subtitle-1">
              {{ $t(`button.login`) }}
            </v-btn>
            <app-button
              :customClass="'buttonPrimary subtitle-1 full-width font-weight-bold pa-2 theme-button embedded-register-button'"
              :action="openRegisterDialog"
              :title="$t(`label.signUp`)"
            ></app-button>
          </v-col>
          <v-col cols="12" class="text-center hidden-md-and-up">
            <v-btn @click="this.openLoginDialog" color="title_color" class="mr-1 primary-button embedded-mobile-login-button font-weight-bold yellow--text">{{ $t(`button.login`) }}</v-btn>
            <app-button :customClass="'buttonPrimary theme-button embedded-mobile-register-button'" :action="this.openRegisterDialog" :title="$t(`label.signUp`)"></app-button>
          </v-col>
        </v-row>
        <!--        mobile beforeLogin view-->
        <v-card class="mobile-refLayout-before-login-card hidden-md-and-up">
          <!--          <v-col cols="6">{{ $t(`message.referralNoLogin`) }}</v-col>-->
          <!--          <v-card-subtitle>{{ $t(`message.referralNoLogin`) }}</v-card-subtitle>-->
          <v-row no-gutters >
            <v-col cols="12" class="mobile-beforeLogin-text">{{ $t(`message.referralNoLogin`) }}</v-col>
            <v-col cols="12">
              <v-row style="flex-direction: column;gap: 1rem;">
                <v-col cols="7"  class="mx-auto inpage-before-login-first text-center beforeLoginBtn" @click="goToRegisterPage()">
                  <b>{{ $t(`label.register`) }}</b>
                </v-col>
                <v-col cols="7" class="mx-auto  inpage-navigator-before-login-second text-center beforeLoginBtn" @click="goToLoginPage()">
                  <b>{{ $t(`label.login`) }}</b>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!--            <v-card-subtitle cols="12">{{ $t(`message.referralNoLogin`) }}</v-card-subtitle>-->
          <!--        <v-btn>signup</v-btn>-->
          <!--          <v-btn>login</v-btn>-->
        </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ReferralSummary from '@/assets/svg/referral-summary.svg'
import ReferralHistory from '@/assets/svg/referral-history.svg'
import ReferralReport from '@/assets/svg/referral-report.svg'
import ReferralTier from '@/assets/svg/referral-tier.svg'
import { uiHelper, locale } from '@/util'
import { ROUTE_NAME } from '@/constants/route.constants'
// import ReferralTier from './referralTier.vue'
// import ReferralSummary from './referralSummary.vue'
// import ReferralReport from './referralReport.vue'
// import ReferralHistory from './referralHistory.vue'
export default {
  name: 'referralLayout',
  components: {
    ReferralSummary,
    ReferralHistory,
    ReferralReport,
    ReferralTier
  },
  metaInfo: {
    title: `${locale.getMessage('meta.default_title')}`,
    titleTemplate: `%s | ${uiHelper.getHost()}`,
    meta: [{ charset: 'utf-8' }, { name: 'description', content: `${locale.getMessage('meta.default_description')}` }, { name: 'keyword', content: `${locale.getMessage('meta.default_keyword')}` }]
  },
  data: () => ({
    selectedTab: 'tier',
    alertShow: false,
    alertSuccess: false,
    alertMessage: '',
    routeName: ROUTE_NAME
  }),
  computed: {
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    },
    currentRouteName() {
      return this.$route.name
    }
  },
  watch: {
    alertShow() {
      if (!this.alertShow) {
        this.alertSuccess = false
        this.alertMessage = ''
      }
    }
  },
  methods: {
    goToRegisterPage() {
      this.$router.push({
        name: ROUTE_NAME.REGISTER
      })
    },
    goToLoginPage() {
      this.$router.push({
        name: ROUTE_NAME.LOGIN
      })
    },
    naviToTier() {
      this.$router.push({
        name: ROUTE_NAME.REFERRAL_TIER
      })
    },
    naviToSummary() {
      this.$router.push({
        name: ROUTE_NAME.REFERRAL_SUMMARY
      })
    },
    openLoginDialog() {
      this.$parent.openLoginDialog()
    },
    openRegisterDialog() {
      this.$parent.openRegisterDialog()
    },
    openAlert(success, msg) {
      this.alertSuccess = success
      this.alertMessage = msg
      this.alertShow = true
    }
  }
}
</script>

<style lang="scss">
.mobile-refLayout-before-login-card.v-card {
  background-color: #1d1d1d;
  text-align: center;
  display: flex;
  height: 170px;
  margin-left: 25px;
  margin-right: 25px;
  border-radius: 10px;
  color: #ffffff;

  .beforeLoginBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
  }
  .inpage-before-login-first {
    color: white;
    background-color: var(--v-newMobilePrimary-base);
    border-radius: 15px;
    height: 50px;
  }

  .inpage-navigator-before-login-second {
    color: black;
    background-color: var(--v-newMobileSecondary-base);
    border-radius: 15px;
    height: 50px;
  }
  .v-card__subtitle {
    color: #ffffff;
  }

  .mobile-beforeLogin-text{
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.mobile-header.referral {
  background-color: var(--v-newMobileSecondaryDarker-base);
  color: #000;
  height: 50px;
  .mobile-header-item {
    display: flex;
    justify-content: center;
    align-items: center;
    &.selected {
      background: radial-gradient(80.17% 665.36% at 17.36% 30.95%, #848A2B 0%, #435131 100%);
      border-bottom: 5px solid var(--v-newMobilePrimary-base);
      color: var(--v-newMobileSecondary-base);
    }
  }
}
.profile-alert-success {
  .v-icon {
    color: #25b34b;
  }
}
.profile-alert-failed {
  .v-icon {
    color: #dc2d2d;
  }
}
.referralLayout {
  border-radius: 20px !important;
  .referralContainer {
    border: 1px solid #ebebeb;
    padding: 36px 16px;
  }
  .referral-tab {
    width: 150px;
    margin-right: 16px;
    padding: 10px;
    background-color: #fff;
    text-transform: capitalize;
    letter-spacing: 0px !important;
  }
  .referral-tab-mobile {
    margin-right: 10px;
    background-color: #fff;
    text-transform: capitalize;
    padding: 0px 10px !important;
    letter-spacing: 0px !important;
  }
  .mobile-nav-active {
    background-color: var(--v-primary-base) !important;
    color: #fff !important;
    font-size: 14px;
    span {
      word-break: break-all;
      white-space: pre-line;
    }
    .mobile-icon {
      fill: #fff !important;
      path {
        fill: #fff !important;
      }
    }
  }
}
.mobile-referral-expansion {
  .v-expansion-panel {
    background-color: transparent !important;
    .v-expansion-panel-header {
      .v-expansion-panel-header__icon {
        i {
          color: #fff !important;
        }
      }
    }
  }
}
@media (max-width: 959px) {
  .embedded-mobile-login-button {
    width: 70px !important;
    height: 30px !important;
    font-size: 12px !important;
  }
  .embedded-mobile-register-button {
    width: 90px !important;
  }
}
@media (max-width: 425px) {
  .referralLayout {
    border-radius: 20px !important;
    .referralContainer {
      border: 1px solid #ebebeb;
      padding: 16px 4px;
    }
    .referral-tab-mobile {
      margin-right: 5px;
      background-color: #fff;
      text-transform: capitalize;
      padding: 0px 5px !important;
      letter-spacing: 0px !important;
    }
    .mobile-nav-active {
      background-color: var(--v-primary-base) !important;
      color: #fff !important;
      font-size: 12px;
    }
  }
}
</style>
